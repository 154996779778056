import React, { useEffect } from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import { StaticImage } from "gatsby-plugin-image"

const Navigation = ({ headerRef }) => {
	useEffect(() => {
		if (typeof window !== "undefined" && typeof document !== "undefined") {
			const handleScroll = () => {
				if (headerRef.current) {
					headerRef.current.classList.toggle("header-scrolled", window.scrollY > 5)
				}
			}

			window.addEventListener("scroll", handleScroll)

			return () => {
				window.removeEventListener("scroll", handleScroll)
			}
		}
	}, [headerRef])

	return (
		
			<Navbar ref={headerRef}  expand="md" id="main-navbar" className="navbar-dark">
				<Container>
					<Navbar.Brand as={Link} to="/">
						<StaticImage src="../../assets/andros-logo.png" alt="ANDROS" />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
						<Nav>
							<ul className="navbar-nav ml-auto">
								<li className="nav-item">
									<Nav.Link href="#hero-section" className="scrollto">
										Home
									</Nav.Link>
								</li>
								<li className="nav-item">
									<Nav.Link href="#application-section" className="scrollto">
										Use Cases
									</Nav.Link>
								</li>
								<li className="nav-item">
									<Nav.Link href="#specs-section" className="scrollto">
										Specifications
									</Nav.Link>
								</li>
								<li className="nav-item">
									<Nav.Link href="#company-section" className="scrollto">
									Company
									</Nav.Link>
								</li>
								<li className="nav-item">
									<Nav.Link href="#contact-section" className="scrollto">
										Contact
									</Nav.Link>
								</li>
							</ul>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		
	)
}

export default Navigation
