import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./src/scss/styles.scss";
import AOS from "aos";
import Wrapper from "./src/components/layout/wrapper";

// Initialize AOS library for animations on scroll
export const onInitialClientRender = () => {
	AOS.init({
		duration: 1000,
		easing: "ease-in-out",
		once: true,
		mirror: false,
	});
};

// Reinitialize AOS library after route updates
export const onRouteUpdate = () => {
	AOS.refresh();
};

export const wrapPageElement = ({ element, props }) => <Wrapper location={props.location}>{element}</Wrapper>;
