import { React, FontAwesomeIcon, fa } from "../../utils/imports"

const Footer = () => {
	return (
		<section id="footer" className="footer">
			<div className="container text-center">
				<div className="copyright">
					© Copyright{" "}
					<strong>
						<span>ANDROS</span>
					</strong>
					. All Rights Reserved
				</div>
			</div>
			<a href="#hero-section" className="scrollto d-flex align-items-center justify-content-center mt-3"
				onClick={e => {
					e.preventDefault()
					document.querySelector("#hero-section").scrollIntoView({
						behavior: "smooth",
					})
				}}
			>
				<FontAwesomeIcon icon={fa.fal["arrow-up"]} />
			</a>
		</section>
	)
}

export default Footer
