import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import Footer from "./footer"
import Navigation from "./navigation"
import "./../../scss/styles.scss"
import { useLocation } from "@reach/router"

const Wrapper = ({ children }) => {
	const location = useLocation()
	const headerRef = useRef(null)

	const currentPath = location.pathname.replace(/\/$/, "") // Remove trailing slash

	const handleLinkClick = event => {
		if (typeof window !== "undefined" && typeof document !== "undefined") {
			event.preventDefault()
			const targetId = event.target.getAttribute("href")
			const targetElement = document.querySelector(targetId)
			if (targetElement) {
				const header = headerRef.current
				const offset = header ? header.offsetHeight + 10 : 0
				window.scrollTo({
					top: targetElement.offsetTop - offset,
					behavior: "smooth",
				})
			}
		}
	}

	useEffect(() => {
		if (typeof document !== "undefined") {
			const links = document.querySelectorAll(".scrollto")
			links.forEach(link => {
				link.addEventListener("click", handleLinkClick)
			})

			return () => {
				links.forEach(link => {
					link.removeEventListener("click", handleLinkClick)
				})
			}
		}
	}, [])

	// Generate the page class name based on the pathname
	const pageClass = `page-${currentPath.substring(1).replace(/\//g, "-") || "index"}`

	return (
		<>
			<Helmet>
				<link rel="apple-touch-icon" sizes="57x57" href="images/favicon/apple-icon-57x57.png" />
				<link rel="apple-touch-icon" sizes="60x60" href="images/favicon/apple-icon-60x60.png" />
				<link rel="apple-touch-icon" sizes="72x72" href="images/favicon/apple-icon-72x72.png" />
				<link rel="apple-touch-icon" sizes="76x76" href="images/favicon/apple-icon-76x76.png" />
				<link rel="apple-touch-icon" sizes="114x114" href="images/favicon/apple-icon-114x114.png" />
				<link rel="apple-touch-icon" sizes="120x120" href="images/favicon/apple-icon-120x120.png" />
				<link rel="apple-touch-icon" sizes="144x144" href="images/favicon/apple-icon-144x144.png" />
				<link rel="apple-touch-icon" sizes="152x152" href="images/favicon/apple-icon-152x152.png" />
				<link rel="apple-touch-icon" sizes="180x180" href="images/favicon/apple-icon-180x180.png" />
				<link rel="icon" type="image/png" sizes="192x192" href="images/favicon/android-icon-192x192.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="images/favicon/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="96x96" href="images/favicon/favicon-96x96.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="images/favicon/favicon-16x16.png" />
				<link rel="manifest" href="manifest.json" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="msapplication-TileImage" content="images/favicon/ms-icon-144x144.png" />
				<meta name="theme-color" content="#ffffff" />
				<meta name="viewport" content="width=device-width,initial-scale=1.0" />
			</Helmet>
			<div className={pageClass}>
				<Navigation headerRef={headerRef} />
				{children}
			</div>
			<Footer />
		</>
	)
}

Wrapper.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Wrapper
